import React from 'react'
import { DeliveryService } from 'react-at-rest'
import BaseStore from '../../stores/BaseStore'

export class TerritoryText extends DeliveryService {
  constructor (props) {
    super(props)
    this.territoryStore = new BaseStore('territories')
  }

  bindResources (props) {
    this.retrieveResource(this.territoryStore, {
      id: props.addressState.substr(1),
      query: {
        $disableSoftDelete: true
      },
      namespace: 'territory'
    })
  }

  render () {
    if (!this.state.loaded) return null

    return (
      <span>{'Territory: ' + this.state.territory.name}</span>
    )
  }
}
