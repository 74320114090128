export default {
  siteName: (function () {
    switch (process.env.REACT_APP_HOSTNAME) {
      case 'www.ccc-billing.com':
        return 'Mobile Dealer Tint'
      case 'www.cse-billing.com':
        return 'Converter Shield'
      case 'www.tag-billing.com':
      case 'www.tagaddendums.com':
        return 'The Addendum Guys'
      case 'www.recovr-billing.com':
        return 'Recovr'
      case 'www.dvsoftware-billing.com':
        return 'DVSoftware'
      default:
        return 'Mobile Clear Shield'
    }
  }.apply()),
  siteShortName: (function () {
    switch (process.env.REACT_APP_HOSTNAME) {
      case 'www.ccc-billing.com':
        return 'CCC'
      case 'www.cse-billing.com':
        return 'CSE'
      case 'www.tag-billing.com':
      case 'www.tagaddendums.com':
        return 'TAG'
      case 'www.recovr-billing.com':
        return 'Recovr'
      case 'www.dvsoftware-billing.com':
        return 'DVS'
      default:
        return 'MCS'
    }
  }.apply()),
  labelPreviewPackageId: (function () {
    switch (process.env.REACT_APP_HOSTNAME) {
      case 'www.ccc-billing.com':
        return '350'
      case 'www.tag-billing.com':
      case 'www.tagaddendums.com':
        return '350'
      case 'www.recovr-billing.com':
        return '350'
      case 'www.dvsoftware-billing.com':
        return '350'
      default:
        return '8'
    }
  }.apply()),
  googleApiKey: 'AIzaSyB3kHrSbkjT36_lCqCKShdV0KBXDciItbo',
  isCCC: process.env.REACT_APP_HOSTNAME === 'www.ccc-billing.com',
  isCSE: process.env.REACT_APP_HOSTNAME === 'www.cse-billing.com',
  isMCS: process.env.REACT_APP_HOSTNAME === 'www.mcs-billing.com',
  isTAG: process.env.REACT_APP_HOSTNAME === 'www.tag-billing.com' || process.env.REACT_APP_HOSTNAME === 'www.tagaddendums.com',
  isRecovr: process.env.REACT_APP_HOSTNAME === 'www.recovr-billing.com',
  isDVSoftware: process.env.REACT_APP_HOSTNAME === 'www.dvsoftware-billing.com',
  isDev: process.env.REACT_APP_IS_DEV === 'true',
  s3Url: (function () {
    if (!window) return 'https://mcsbilling.sfo3.digitaloceanspaces.com/dev/'

    if (window.location.href.match(/(www|beta)\.\w*-billing\.com/)) {
      return 'https://mcsbilling.sfo3.digitaloceanspaces.com/prd/'
    }

    return 'https://mcsbilling.sfo3.digitaloceanspaces.com/dev/'
  }.apply()),
  siteClassName: process.env.REACT_APP_HOSTNAME.split('.')[1],
  siteLogo: `${process.env.PUBLIC_URL}/${process.env.REACT_APP_HOSTNAME}/logo.png`,
  primaryButtonColor: (function () {
    switch (process.env.REACT_APP_HOSTNAME) {
      case 'www.ccc-billing.com':
      case 'www.cse-billing.com':
        return '#e7c104'
      case 'www.tag-billing.com':
        return '#b02318'
      case 'www.dvsoftware-billing.com':
        return '#438C6E'
      default:
        return '#1988fb'
    }
  }.apply())
}
