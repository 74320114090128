import React from 'react'
import { AppEvents, EventableComponent } from 'react-at-rest'
import { Redirect } from 'react-router-dom'

class Authorized extends EventableComponent {
  constructor (props) {
    super(props)
    this.listenTo(AppEvents, 'api.exception', this.handleApiException)
  }

  handleApiException = (exception) => {
    if (exception.status === 401) { // unauthorized
      window.localStorage.clear()
      this.forceUpdate()
    }
  }

  render () {
    const children = this.props.children

    if (window.localStorage.accessToken) {
      return (
        <div>
          {children}
        </div>
      )
    }
    return (
      <Redirect to={{ pathname: '/login', state: { fromUrl: window.location.pathname } }} />
    )
  }
}

export default Authorized
