import React from 'react'
import ReactDOM from 'react-dom'
import _ from 'lodash'
import App from './App'
import { unregister } from './registerServiceWorker'
import './index.scss'
import { Store, RestForm, DeliveryService } from 'react-at-rest'
import * as Promise from 'bluebird'

// use bluebird instead of native Promises
window.Promise = Promise

Store.API_PATH_PREFIX = '/api'
Store.API_ENVELOPE = false
Store.SUPERAGENT_PLUGINS = [(request) => {
  request.set('X-Source', 'Web')
  if (window.localStorage.accessToken) {
    request.set('Authorization', `Bearer ${window.localStorage.accessToken}`)
  }
}]

RestForm.prototype.parseErrors = (body) => {
  const errors = {}
  if (!_.isArray(body.errors)) return body

  body.errors.map((error) => {
    errors[error.path] = [error.message.replace('cannot be null', 'can\'t be left blank.')]
    return errors
  })

  return {
    errorMessage: body.message,
    errors: errors
  }
}

DeliveryService.prototype.reloadResources = function (props = this.props) {
  this.boundResources = []
  this.bindResources(props)
  this.getResources()
}

ReactDOM.render(<App />, document.getElementById('root'))

unregister()
