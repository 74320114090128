import React from 'react'
import { Route, Link, Switch } from 'react-router-dom'
import { AddressStateText } from './AddressStateText'

export function Header (props) {
  return (<header className='main row align-items-center'>
    <div className='col-md-2 col-sm'>
      <Switch>
        <Route path='/:addressState(\w{2,10})/'
          render={({
            match
          }) => <Link to={`/${match.params.addressState}/`}><img src={props.siteLogo}
            id='logo'
            alt='' /></Link>} />
        <Route path='/'
          render={() => <Link to={'/'}><img src={props.siteLogo}
            id='logo'
            alt='' /></Link>} />
      </Switch>
    </div>
    <div className='col-md col-sm-12 d-flex justify-content-end'>
      <nav className='nav'>
        <span className='nav-link'
          style={{
            fontSize: 12
          }}><a href='/logout'
            onClick={props.logout}>Log Out</a></span>
        <span className='nav-link'
          style={{
            fontSize: 12
          }}>{props.userName()}</span>
      </nav>
      <Switch>
        <Route path='/:addressState(\w{2,10})/'
          render={({
            match
          }) => <Link style={{
            marginRight: '20px',
            fontSize: 20,
            color: '#fd9f27'
          }}
          to='/'><AddressStateText addressState={match.params.addressState} /></Link>} />
      </Switch>
    </div>
    {window.location.host.indexOf('localhost') >= 0 && <h1 style={{
      color: 'red',
      position: 'absolute',
      top: 20,
      left: '40%',
      fontSize: '2vh'
    }}>LOCAL SERVER</h1>}
    {window.location.host.indexOf('dev.surgeforward.com') >= 0 && <h1 style={{
      color: 'red',
      position: 'absolute',
      top: 20,
      left: '30%'
    }}>DEVELOPMENT SERVER</h1>}
  </header>)
}
