import React, { Component } from 'react'
import LoginForm from './LoginForm'
import config from '../../config'

class Login extends Component {
  componentDidMount = () => {
    if (window.localStorage.accessToken) {
      this.props.history.push('/')
    }
  }

  handleSuccess = (response) => {
    if (!window.localStorage.accessToken) return

    let toUrl = '/'
    if (this.props.location.state) {
      toUrl = this.props.location.state.fromUrl || '/'
    }
    if (toUrl === '/login') toUrl = '/'

    this.props.history.push(toUrl)
  }

  handleError = () => {
  }

  render () {
    return (
      <div>
        <LoginForm
          store={this.props.store}
          onSuccess={this.handleSuccess}
          onError={this.handleError}
          model={{}} />
        {config.isTAG && <img
          style={{
            width: 300,
            margin: '50px auto 0',
            display: 'flex'
          }}
          src="/images/TAG_logo_small.png"
          alt="TAG" />}
      </div>
    )
  }
}

export default Login
