import React, { Component } from 'react'
import Modal from 'react-modal'
import config from '../../config'

class AddendumHeader extends Component {
  constructor (props) {
    super(props)
    this.state = { showModal: false }
  }

  showHelp = e => {
    e.preventDefault()
    this.setState({ showModal: true })
  }

  render () {
    const modalStyle = {
      content: {
        width: 320,
        height: 350,
        margin: '100px auto 0',
        color: '#4a4a4a'
      },
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.75)'
      }
    }

    return <header className='main row align-items-center AddendumHeader'>
      <div className='col'
        onClick={() => { document.location = '/' }}
        style={{ cursor: 'pointer' }}>
        {config.siteName}
      </div>
      <div className="logout mt-5 mt-sm-0">
        <a href='/help'
          onClick={this.showHelp}>Help</a>

        <a href='/logout'
          onClick={this.props.logout}
          style={{ paddingLeft: 30 }}>Log Out</a>
      </div>

      <Modal
        isOpen={this.state.showModal}
        contentLabel='Help'
        onRequestClose={() => { this.setState({ showModal: false }) }}
        style={modalStyle}>
        <h4>Customer Support</h4>
        {config.isCSE ? <div>
          <h5>General Help:</h5>
          <h6 style={{ fontSize: '85%' }}>Converter Shield<br/>
            17644 Chatsworth St<br/>Granada Hills CA 91344<br/>
          </h6>
          <h6 style={{ fontSize: '85%' }}>
            toll free: (800) 448-3574
          </h6>
          <h6 style={{ fontSize: '85%' }}><a href="https://www.convertershield.com/">www.convertershield.com</a></h6>
          <h6 style={{ fontSize: '85%' }}>Hilda Yepiz</h6>
          <h6 style={{ fontSize: '85%' }}><a href="mailto:hyepiz@convertershield.com">hyepiz@convertershield.com</a></h6>
        </div> : <div>
          <h5>General Help:</h5>
          <h6 style={{ fontSize: '85%' }}>Toll Free: <a href="tel:818-360-3831">818-360-3831</a></h6>
          <h6 style={{ fontSize: '85%' }}>Email: <a href="mailto:tag@theaddendumguys.com">tag@theaddendumguys.com</a></h6>
          <h5>Billing Concerns:</h5>
          <h6 style={{ fontSize: '85%' }}><a href="mailto:invoices@theaddendumguys.com">invoices@theaddendumguys.com</a></h6>
          <h6 style={{ fontSize: '85%' }}><a href="tel:800-821-8436">800-821-8436</a></h6>
          <h5 style={{ fontSize: '85%' }}>To Order Supplies: (Please sign in using your TAG app login) and go to address below.</h5>
          <h6 style={{ fontSize: '85%' }}><a href="https://www.tagaddendums.com/">www.tagaddendums.com</a></h6>
        </div>}
        <button onClick={() => { this.setState({ showModal: false }) }}
          className="mt-4">
          Done
        </button>
      </Modal>
    </header>
  }
}

export default AddendumHeader
