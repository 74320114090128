import React from 'react'
import { DeliveryService } from 'react-at-rest'
import BaseStore from '../../stores/BaseStore'

export class RegionText extends DeliveryService {
  constructor (props) {
    super(props)
    this.regionStore = new BaseStore('regions')
  }

  bindResources (props) {
    this.retrieveResource(this.regionStore, {
      id: props.addressState.substr(1),
      query: {
        $disableSoftDelete: true
      }
    })
  }

  render () {
    if (!this.state.loaded) return null

    return (
      <span>{'Region: ' + this.state.region.name}</span>
    )
  }
}
