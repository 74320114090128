import React from 'react'
import { Forms, RestForm } from 'react-at-rest'

class LoginForm extends RestForm {
  parseErrors (data) {
    if (data.code === 401) {
      return {
        errorMessage: 'Invalid username or password'
      }
    }
    return {
      errorMessage: data.message
    }
  }

  render () {
    return (
      <div style={{ maxWidth: 350, margin: '150px auto 0' }}>
        <form onSubmit={this.handleSubmit}
          className='d-flex flex-column' >
          <Forms.ErrorSummary message={this.state.errorMessage} />
          <Forms.TextInput
            {...this.getFieldProps('email')}
            label="Username" />
          <Forms.PasswordInput {...this.getFieldProps('password')} />
          <button className='btn btn-primary align-self-start'>Log In</button>
        </form>
      </div>
    )
  }
}

export default LoginForm
