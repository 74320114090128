import LoadableComponent from 'react-loadable'

const Loadable = (loader) => LoadableComponent({
  loader,
  loading () {
    return null
  }
})

export default Loadable
