import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { EventableComponent } from 'react-at-rest'
import classnames from 'classnames'
import config from './config'

import './App.scss'
import { Helmet } from 'react-helmet'
import Authorized from './components/auth/Authorized'
import BaseStore from './stores/BaseStore'
import Login from './components/auth/Login'
import SessionStore from './stores/SessionStore'
import Loadable from './components/Loadable'
import { Header } from './components/header/Header'
import AddendumHeader from './components/addendums/AddendumHeader'

class App extends EventableComponent {
  constructor (props) {
    super(props)

    this.state = {}

    this.sessionStore = new SessionStore('authentication')
    this.userStore = new BaseStore('users')

    // ensure everything is updated when the logged in user changes
    this.listenTo(this.sessionStore, 'create', () => {
      this.loadSessionUser()
    })
  }

  componentWillMount () {
    this.loadSessionUser()
  }

  logout = (e) => {
    e.preventDefault()
    window.localStorage.clear()
    this.forceUpdate()
  }

  async loadSessionUser () {
    if (!window.localStorage.userId) return

    const data = await this.userStore.getResource(window.localStorage.userId)
    this.setState({ user: data.user })
  }

  // eslint-disable-next-line react/display-name
  userName = () => {
    if (!this.state.user) return
    return (<span>{this.state.user.name}</span>)
  }

  render () {
    const { siteLogo, siteName, siteClassName } = config
    let Routes = () => null

    if (this.state.user && this.state.user.role === 'admin') {
      Routes = Loadable(() => import(/* webpackChunkName: "admin" */ './AdminRoutes'))
    }
    if (this.state.user && this.state.user.role === 'servicerequest') {
      Routes = Loadable(() => import(/* webpackChunkName: "servicerequest" */ './ServiceRequestRoutes'))
    }
    if (config.isTAG && this.state.user && this.state.user.role === 'dealer') {
      Routes = Loadable(() => import(/* webpackChunkName: "addendum" */ './AddendumRoutes'))
    }
    if (config.isCSE && this.state.user && this.state.user.role === 'dealer') {
      Routes = Loadable(() => import(/* webpackChunkName: "dailyvin" */ './LaserEtchRoutes'))
    }

    return (
      <Router>
        <div className={classnames('App container-fluid', siteClassName)}>
          <Helmet
            defaultTitle={siteName}
            titleTemplate={`%s - ${siteName}`} />
          <Route exact
            path='/login'
            render={(props) => <Login store={this.sessionStore}
              {...props} />} />

          <Authorized store={this.sessionStore}>

            {(this.state.user && this.state.user.role === 'dealer')
              ? <AddendumHeader logout={this.logout} />
              : <Header logout={this.logout}
                userName={this.userName}
                siteLogo={siteLogo} />
            }

            <Routes user={this.state.user} />

          </Authorized>
        </div>
      </Router>
    )
  }
}

export default App
