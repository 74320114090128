import { Store } from 'react-at-rest'
import _ from 'lodash'

class BaseStore extends Store {
  currencyFields = []

  path (action, id, { parentResourcesKey, parentResourceId, query } = {}) {
    let path = ''
    if (action === 'index' || action === 'create') {
      path += `${parentResourcesKey ? '/' + parentResourcesKey : ''}/${_.kebabCase(this.resourcesKey)}`
    } else {
      path += `/${_.kebabCase(this.resourcesKey)}`
      if (id) { path += `/${id}` }
    }

    return path
  }

  // convert currency fields into local values
  parseResource (data) {
    this.parseCurrency(data, this.currencyFields)
    return data
  }

  parseAll (data) {
    for (const d of data.data) { this.parseCurrency(d, this.currencyFields) }
    data.data.meta = {
      total: data.total,
      skip: data.skip,
      limit: data.limit
    }
    return data.data
  }

  // pre-convert local model values before saving to API
  createResource (model, options) {
    // use null for empty values
    for (const key in model) {
      if (model[key] === '') model[key] = null
    }
    this.convertCurrency(model, this.currencyFields)
    return super.createResource(model, options)
  }

  updateResource (id, patch, options) {
    // use null for empty values
    for (const key in patch) {
      if (patch[key] === '') patch[key] = null
    }
    this.convertCurrency(patch, this.currencyFields)
    return super.updateResource(id, patch, options)
  }

  // allow destroy to use a query string
  destroyResource (id, options) {
    const url = this.getPath('destroy', id, options)
    return this.delete(url)
      .then(data => {
        this.unstoreResource(id)
        this.trigger('destroy', this.resources, id)
      })
  }

  // API currency values are integers of cents
  convertCurrency (data, fields) {
    for (const field of fields) {
      if (_.get(data, field)) { _.set(data, field, parseFloat(_.get(data, field)) * 100) }
    }
  }

  parseCurrency (data, fields) {
    for (const field of fields) {
      if (_.get(data, field)) { _.set(data, field, (_.get(data, field) / 100).toFixed(2)) }
    }
  }
}

export default BaseStore
