import React from 'react'
import { RegionText } from './RegionText'
import { TerritoryText } from './TerritoryText'
import { ZoneText } from './ZoneText'

export function AddressStateText ({ addressState }) {
  if (addressState === 'USA') {
    return <span>USA</span>
  }
  if (addressState.match(/Z\d+/i)) {
    return <ZoneText addressState={addressState} />
  }
  if (addressState.match(/R\d+/i)) {
    return <RegionText addressState={addressState} />
  }
  if (addressState.match(/T\d+/i)) {
    return <TerritoryText addressState={addressState} />
  }

  return (<span>{addressState}</span>)
}
